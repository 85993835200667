import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state, this is primarily used for the  shopping cart
export interface IUser {
  firstName: string
  lastName: string
  teamName: string
}
interface State {
  referalCode?: string
  userData?: IUser
  loading?: boolean
  error?: boolean
  backgroundImage?: string
}

const initialState: State = {}

export const orderSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setReferalCode: (s, { payload }: PayloadAction<string>) => {
      s.referalCode = payload
    },
    setBackgroundImage: (s, { payload }: PayloadAction<string>) => {
      s.backgroundImage = payload
    },
    setUserData: (s, { payload }: PayloadAction<IUser>) => {
      s.userData = payload
    },
  },
})

export const userActions = orderSlice.actions

export default orderSlice.reducer
