const DeliveryTruck = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={30}
    height={30}
    x={0}
    y={0}
    viewBox="0 0 512 512"
    style={{
      enableBackground: 'new 0 0 512 512',
    }}
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <path
        d="M159.6 400.6h176.733V288.133H15v80.333a32.115 32.115 0 0 0 9.415 22.726 32.122 32.122 0 0 0 22.718 9.407H63.2"
        style={{
          strokeWidth: 28,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
        fill="none"
        stroke="#f6a237"
        strokeWidth={28}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        data-original="#000000"
        opacity={1}
      />
      <path
        d="M336.333 288.133v-192.8a32.11 32.11 0 0 0-9.415-22.718A32.13 32.13 0 0 0 304.2 63.2H47.133a32.13 32.13 0 0 0-22.718 9.415A32.11 32.11 0 0 0 15 95.333v192.8h321.333z"
        style={{
          strokeWidth: 28,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
        fill="none"
        stroke="#f6a237"
        strokeWidth={28}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        data-original="#000000"
        opacity={1}
      />
      <path
        d="M432.733 400.6h32.133a32.122 32.122 0 0 0 22.718-9.407 32.115 32.115 0 0 0 9.415-22.726V239.934c0-44.36-35.965-80.333-80.333-80.333h-80.333v241"
        style={{
          strokeWidth: 28,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
        fill="none"
        stroke="#f6a237"
        strokeWidth={28}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        data-original="#000000"
        opacity={1}
      />
      <circle
        cx={111.4}
        cy={400.6}
        r={48.2}
        style={{
          strokeWidth: 28,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
        fill="none"
        stroke={color || '#F6A237'}
        strokeWidth={28}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        data-original="#000000"
        opacity={1}
      />
      <circle
        cx={384.533}
        cy={400.6}
        r={48.2}
        style={{
          strokeWidth: 28,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
        fill="none"
        stroke={color || '#F6A237'}
        strokeWidth={28}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        data-original="#000000"
        opacity={1}
      />
      <path
        d="M416.667 159.6v128.533H497"
        style={{
          strokeWidth: 28,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
        fill="none"
        stroke="#f6a237"
        strokeWidth={28}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        data-original="#000000"
        opacity={1}
      />
    </g>
  </svg>
)
export default DeliveryTruck
