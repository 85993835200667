// If you make changes to the store or the checkout form
// You need to change these cache keys in order to clear the
// cache on the clients browser

/**
 * For the Checkout Page form
 */
export const CheckoutFormKey = 'checkoutForm'

/**
 * For the Persisted Redux state
 */
export const ReduxCacheKey = 'root1'
