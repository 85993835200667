import {
  type AnyAction,
  combineReducers,
  configureStore,
  type ThunkDispatch,
} from '@reduxjs/toolkit'
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector as useReduxSelector,
} from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { ReduxCacheKey } from 'src/utils/cacheKeys'
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import order from './order'
import user from './user'

const reducer = combineReducers({
  order,
  user,
})

const persistedReducer = persistReducer(
  {
    key: ReduxCacheKey,
    storage,
    version: 1,
  },
  reducer
)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: true,
})

export const persistor = persistStore(store)

export type StateType = ReturnType<typeof reducer>
export type AppDispatch = ThunkDispatch<StateType, null, AnyAction>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<StateType> = useReduxSelector

// Used by createAsyncThunk See (https://redux-toolkit.js.org/usage/usage-with-typescript#createasyncthunk)
export type AsyncThunkConfig = {
  dispatch: AppDispatch
  state: StateType
}

export default store
