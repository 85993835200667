const Toplist = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="30"
    height="30"
    x="0"
    y="0"
    viewBox="0 0 682.667 682.667"
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <defs strokeWidth="20">
        <clipPath id="a" clipPathUnits="userSpaceOnUse" strokeWidth="20">
          <path
            d="M0 512h512V0H0Z"
            fill="#f6a237"
            opacity="1"
            data-original="#000000"
            strokeWidth="20"
          ></path>
        </clipPath>
      </defs>
      <g
        clipPath="url(#a)"
        transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
        strokeWidth="20"
      >
        <path
          d="M0 0h-115.405a5.137 5.137 0 0 1-5.143-5.143v-115.408c0-2.845 2.298-5.172 5.143-5.172H0c2.844 0 5.171 2.327 5.171 5.172V-5.143C5.171-2.298 2.844 0 0 0Z"
          transform="translate(153.035 504.5)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0h-133.677v-107.191h293.268l28.04 53.581L159.591 0H35.078"
          transform="translate(291.883 495.249)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0h-115.405c-2.845 0-5.143-2.327-5.143-5.171V-120.58c0-2.815 2.298-5.142 5.143-5.142H0c2.844 0 5.171 2.327 5.171 5.142V-5.171C5.171-2.327 2.844 0 0 0Z"
          transform="translate(153.035 318.875)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0v107.22h293.268l28.04-53.61L293.268 0Z"
          transform="translate(158.206 202.404)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0h-115.405a5.138 5.138 0 0 1-5.143-5.143v-115.408c0-2.845 2.298-5.172 5.143-5.172H0c2.844 0 5.171 2.327 5.171 5.172V-5.143C5.171-2.299 2.844 0 0 0Z"
          transform="translate(153.035 133.223)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0h11.808v-55.19"
          transform="translate(89.428 469.248)"
          fill="none"
          stroke={color || '#F6A237'}
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0s-26.833-.345-28.125.086c-1.265.431 2.039 2.643 19.65 27.811 3.304 4.712 5.114 8.676 5.89 11.98l.258 2.155c0 7.786-6.32 14.107-14.134 14.107a14.096 14.096 0 0 1-13.848-11.377"
          transform="translate(110.486 228.002)"
          fill="none"
          stroke={color || '#F6A237'}
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0c1.292 6.493 7.01 11.406 13.876 11.406 7.786 0 14.106-6.321 14.106-14.135 0-7.786-6.32-14.135-14.106-14.135"
          transform="translate(81.47 87.226)"
          fill="none"
          stroke={color || '#F6A237'}
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0c7.786 0 14.106-6.321 14.106-14.106 0-7.815-6.32-14.136-14.106-14.136-7.153 0-13.043 5.286-13.991 12.153a15.01 15.01 0 0 0-.144 1.983"
          transform="translate(95.346 70.361)"
          fill="none"
          stroke={color || '#F6A237'}
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0h-133.677v-107.191h293.268l28.04 53.581L159.591 0H35.078"
          transform="translate(291.883 123.971)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
      </g>
    </g>
  </svg>
)
export default Toplist
