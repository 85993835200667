const Checklist = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    x="0"
    y="0"
    viewBox="0 0 511.998 511.998"
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <path
        d="M325.579 45.002h106.415v451.996H80.004V45.002h106.415M123.585 171.948H272"
        fill="none"
        stroke="#f6a237"
        strokeWidth="25"
        strokeMiterlimit="10"
        data-original="#000000"
        opacity="1"
      ></path>
      <path
        d="m310.263 144.511 27.37 27.369 39.866-39.867M123.585 255.999H272M310.263 228.562l27.37 27.369 39.866-39.867M123.585 340.05H272M310.263 312.613l27.37 27.369 39.866-39.866M123.585 424.102H272M310.263 396.665l27.37 27.369 39.866-39.867"
        fill="none"
        stroke="#f6a237"
        strokeWidth="25"
        strokeMiterlimit="10"
        data-original="#000000"
        opacity="1"
      ></path>
      <path
        d="M186.419 15h139.16v60.004h-139.16z"
        fill="none"
        stroke={color || '#F6A237'}
        strokeWidth="25"
        strokeMiterlimit="10"
        data-original="#000000"
        opacity="1"
      ></path>
    </g>
  </svg>
)
export default Checklist
