const Home = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    x="0"
    y="0"
    viewBox="0 0 682.667 682.667"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 512h512V0H0Z"
            fill="#f6a237"
            opacity="1"
            data-original="#000000"
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
        <path
          d="m0 0 241 240 90-90v60h60V90l91-90"
          transform="translate(15 241)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="30"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0v-270h120v150h120v-150h120V0"
          transform="translate(76 301)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="30"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0c0-16.568-13.432-30-30-30-16.568 0-30 13.432-30 30 0 16.568 13.432 30 30 30C-13.432 30 0 16.568 0 0Z"
          transform="translate(286 301)"
          fill="none"
          stroke={color || '#f6a237'}
          strokeWidth="30"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
      </g>
    </g>
  </svg>
)
export default Home
