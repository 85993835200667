const Shoppingbag = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="28"
    height="28"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        d="M416.667 159.6s30.471 213.321 42.962 300.722A32.135 32.135 0 0 1 427.816 497H84.183a32.133 32.133 0 0 1-31.813-36.678C64.863 372.921 95.333 159.6 95.333 159.6h321.334z"
        fill="none"
        stroke="#f6a237"
        strokeWidth="30"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        data-original="#000000"
        opacity="1"
      ></path>
      <path
        d="M175.667 207.8V95.333C175.667 50.967 211.632 15 256 15s80.333 35.967 80.333 80.333V207.8"
        fill="none"
        stroke={color || '#F6A237'}
        strokeWidth="30"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        data-original="#000000"
        opacity="1"
      ></path>
    </g>
  </svg>
)
export default Shoppingbag
