/**
 * Mui Imports
 */
import React from 'react'

import PersonIcon from '@mui/icons-material/Person'
import { Box, Button, Container, Card } from '@mui/material'

/**
 * Helper Imports
 */
import IKImage from 'src/components/ImageKit/IKImage'

import './styles.css'

export default function Header() {
  /**
   * `onLoginClick` is a function that navigates to the login page
   */
  const onLoginClick = () => {
    // navigate(routes.login())
    window.location.href = '/login'
  }

  /**
   * `onLogoClick` is a function that navigates to the Lagshoppen homepage
   */
  const onLogoClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    window.location.href = 'https://lagshoppen.se/'
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        width: '100%',
        borderRadius: 0,
        zIndex: 1000,
      }}
    >
      <Box
        component={Card}
        sx={{
          borderRadius: 0,
          py: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Container>
          <Box
            sx={{
              width: '100%',

              display: 'flex',

              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {/* Logo */}
            <Box sx={{ cursor: 'pointer' }}>
              <a onClick={onLogoClick}>
                <Box
                  id={'headerlogobox'}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    pt: '4px',
                  }}
                >
                  <IKImage
                    path="Marketing/Graphics/Lagshoppen_stor_ZKE4uiS69.svg?updatedAt=1699448594626"
                    alt="Lagshoppen logo"
                    className="lagshoppenlogoheaderimage"
                    transformation={[
                      {
                        width: '158.9',
                      },
                    ]}
                  />
                </Box>
              </a>
            </Box>

            {/* Icons */}
            <Box sx={{ display: 'flex', gap: 0 }}>
              <Button
                id={'headerloginiconbutton'}
                aria-label="accout login"
                onClick={() => onLoginClick()}
              >
                <PersonIcon sx={{ color: '#2D3748' }} id={'headerloginicon'} />
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
