import React from 'react'

import { Box, useMediaQuery } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useTheme } from '@mui/material/styles'

import { navigate, useLocation } from '@redwoodjs/router'

interface MenuOption {
  icon: React.ComponentType<any>
  title: string
  url?: string
  onClick?: () => unknown
}

interface Props {
  list: MenuOption[]
  mobileOpen: boolean
  handleDrawerToggle: () => void
  window?: () => Window
}

const drawerWidth = 245

const AdminPanel = ({
  list,
  window,
  mobileOpen,
  handleDrawerToggle,
}: Props) => {
  const location = useLocation()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  const drawer = (
    <>
      <Box sx={{ overflow: 'auto', marginTop: 8 }}>
        <List>
          {list.map((list) => (
            <ListItem
              key={list.title}
              onClick={() => {
                handleDrawerToggle()
                if (list.url) navigate(list.url)
                if (list.onClick) list.onClick()
              }}
              button
              selected={location.pathname === list.url}
              sx={{
                color: 'rgba(0, 0, 0, 0.87)',
                borderRadius: 2,
                // '&.Mui-selected': {
                //   bgcolor: 'pick-a-color',
                // },
                //I dont think the below is working. Think it should be Mui-selected and bgcolor
                '&.active': {
                  backgroundColor: '150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                },
              }}
            >
              <Box sx={{ mr: 3 }}>
                {/* color variable affects the color on a small part of the svg */}
                <list.icon
                  color={location.pathname === list.url ? '#2D3748' : ''}
                />
              </Box>
              {/* <ListItemIcon>{list.icon}</ListItemIcon> */}
              <ListItemText primary={list.title} />
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <>
      <Drawer
        container={container}
        variant={isSmall ? 'temporary' : 'permanent'}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          '& .MuiDrawer-paper': { width: drawerWidth, border: 0 },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
    </>
  )
}

export default AdminPanel
