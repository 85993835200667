const Groups = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    x="0"
    y="0"
    viewBox="0 0 682.667 682.667"
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <defs strokeWidth="25">
        <clipPath id="a" clipPathUnits="userSpaceOnUse" strokeWidth="25">
          <path
            d="M0 512h512V0H0Z"
            fill="#f6a237"
            opacity="1"
            data-original="#000000"
            strokeWidth="25"
          ></path>
        </clipPath>
      </defs>
      <g
        clipPath="url(#a)"
        transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
        strokeWidth="25"
      >
        <path
          d="M0 0c0-30.99-25.123-56.113-56.113-56.113S-112.226-30.99-112.226 0s25.123 56.113 56.113 56.113S0 30.99 0 0Z"
          transform="translate(312.113 368.226)"
          fill="none"
          stroke={color || '#F6A237'}
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0c-5.353 1.411-11.136.73-16.029-1.857-14.479-7.658-30.939-12.055-48.459-12.055-17.52 0-33.98 4.397-48.459 12.055C-117.841.73-123.624 1.411-128.977 0c-27.476-7.243-47.737-32.257-47.737-62.008v-96.194c0-17.709 14.356-32.065 32.065-32.065H15.673c17.709 0 32.064 14.356 32.064 32.065v96.194C47.737-32.257 27.477-7.243 0 0Z"
          transform="translate(320.488 277.928)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0c0-22.136-17.945-40.081-40.081-40.081-22.136 0-40.081 17.945-40.081 40.081 0 22.136 17.945 40.081 40.081 40.081C-17.945 40.081 0 22.136 0 0Z"
          transform="translate(448.387 320.13)"
          fill="none"
          stroke={color || '#F6A237'}
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0h80.161c13.282 0 24.048 10.767 24.048 24.048v40.081c.001 31.145-22.218 57.042-51.662 62.84-5.358 1.055-10.965.521-16.065-1.435-8.846-3.391-18.425-5.292-28.466-5.292-2.707 0-5.38.14-8.016.408"
          transform="translate(400.29 119.726)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0c0-22.136 17.945-40.081 40.081-40.081 22.136 0 40.081 17.945 40.081 40.081 0 22.136-17.945 40.081-40.081 40.081C17.945 40.081 0 22.136 0 0Z"
          transform="translate(63.613 320.13)"
          fill="none"
          stroke={color || '#F6A237'}
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0h-80.161c-13.282 0-24.048 10.767-24.048 24.048v40.081c-.001 31.145 22.218 57.042 51.662 62.84 5.358 1.055 10.965.521 16.065-1.435 8.846-3.391 18.425-5.292 28.466-5.292 2.707 0 5.38.14 8.016.408"
          transform="translate(111.71 119.726)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
      </g>
    </g>
  </svg>
)
export default Groups
