import { createContext } from 'react'

export interface AutoLoginProps {
  username: string
  password: string
}

export interface AutoLoginContextProps {
  autoLoginProps: AutoLoginProps
  setAutoLoginProps: (props: AutoLoginProps) => void
}

export const AutoLoginContext = createContext({} as AutoLoginContextProps)
