const Play = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    x="0"
    y="0"
    viewBox="0 0 682.667 682.667"
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 512h512V0H0Z"
            fill="#f6a237"
            opacity="1"
            data-original="#000000"
            className=""
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
        <path
          d="M0 0c0-133.101-107.899-241-241-241S-482-133.101-482 0s107.899 241 241 241S0 133.101 0 0Z"
          transform="translate(497 256)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="26"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0v-260l180 130z"
          transform="translate(186 386)"
          fill="none"
          stroke={color || '#F6A237'}
          strokeWidth="26"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
      </g>
    </g>
  </svg>
)
export default Play
