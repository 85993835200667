import React from 'react'

import { Box } from '@mui/material'
import { Provider } from 'react-redux'

import { Toaster } from '@redwoodjs/web/toast'

import Header from 'src/components/Shop/Header'
import store from 'src/store'

interface ShopLayoutProps {
  children?: React.ReactNode
}

const ShopLayout = ({ children }: ShopLayoutProps) => {
  return (
    <Box sx={{ position: 'relative', overflow: 'clip' }}>
      <>
        <Toaster />
        <Box sx={{ minHeight: '40px' }}>
          <Header />
        </Box>
        <Box>
          <>
            <Box sx={{ minHeight: '100vh' }}>{children}</Box>
          </>
        </Box>
      </>
    </Box>
  )
}

interface ShopProps {
  children?: React.ReactNode
}

const Shop = ({ children }: ShopProps) => {
  return (
    <Provider store={store}>
      <ShopLayout>{children}</ShopLayout>
    </Provider>
  )
}

export default Shop
