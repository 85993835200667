const Milestones = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="30"
    height="30"
    x="0"
    y="0"
    viewBox="0 0 682.667 682.667"
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <defs strokeWidth="25">
        <clipPath id="a" clipPathUnits="userSpaceOnUse" strokeWidth="25">
          <path
            d="M0 512h512V0H0Z"
            fill="#f6a237"
            opacity="1"
            data-original="#000000"
            strokeWidth="25"
          ></path>
        </clipPath>
      </defs>
      <path
        d="M316.25 150.926h-120.5v60.25h120.5zM376.5 90.676h-241v60.25h241z"
        transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
        fill="none"
        stroke="#f6a237"
        strokeWidth="25"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeDasharray="none"
        strokeOpacity=""
        data-original="#000000"
        opacity="1"
        className=""
      ></path>
      <g
        clipPath="url(#a)"
        transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
        strokeWidth="25"
      >
        <path
          d="M436.75 30.426H75.25v60.25h361.5z"
          fill="none"
          stroke="#f6a237"
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
          className=""
        ></path>
        <path
          d="m0 0 33.492-57.724 65.249-14.015-44.55-49.691 6.834-66.386L0-160.802l-61.025-27.014 6.834 66.386-44.55 49.691 65.249 14.015z"
          transform="translate(256 481.574)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
          className=""
        ></path>
        <path
          d="M0 0h44.983"
          transform="translate(15 392.312)"
          fill="none"
          stroke={color || '#f6a237'}
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
          className=""
        ></path>
        <path
          d="m0 0 31.545 32.461"
          transform="translate(57.808 303.05)"
          fill="none"
          stroke={color || '#f6a237'}
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
          className=""
        ></path>
        <path
          d="m0 0 31.545-32.461"
          transform="translate(57.808 481.574)"
          fill="none"
          stroke={color || '#f6a237'}
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
          className=""
        ></path>
        <path
          d="M0 0h-44.983"
          transform="translate(497 392.312)"
          fill="none"
          stroke={color || '#f6a237'}
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
          className=""
        ></path>
        <path
          d="m0 0-31.545-32.461"
          transform="translate(454.192 481.574)"
          fill="none"
          stroke={color || '#f6a237'}
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
          className=""
        ></path>
        <path
          d="m0 0-31.545 32.461"
          transform="translate(454.192 303.05)"
          fill="none"
          stroke={color || '#f6a237'}
          strokeWidth="25"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
          className=""
        ></path>
      </g>
    </g>
  </svg>
)
export default Milestones
