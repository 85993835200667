import { useState } from 'react'

import { StyledEngineProvider } from '@mui/material'
import { yellow, grey } from '@mui/material/colors'
import { ThemeProvider, createTheme, Theme } from '@mui/material/styles'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { RedwoodProvider } from '@redwoodjs/web'
import { Metadata } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import Routes from 'src/Routes'

import './scaffold.css'
import './index.css'

import { AuthProvider, useAuth } from './auth'
import { AutoLoginProps, AutoLoginContext } from './contexts/autoLoginContext'

if (process.env.REDWOOD_ENV_SENTRY_DSN) {
  console.log('Initializing Sentry')
  Sentry.init({
    dsn: process.env.REDWOOD_ENV_SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new CaptureConsoleIntegration({
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['error'],
      }),
    ],
    tracesSampleRate: 0.2,
  })
}

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    common: {
      black: '#2D3748',
      white: '#ffffff',
    },
    primary: {
      ...yellow,
      main: '#F6A237',
      light: '#FEF8F4',
      // light: '#FEFAF5',
    },
    secondary: grey,
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
    h1: {
      fontSize: '3rem',
    },
  },
  transitions: {
    duration: {
      enteringScreen: 500,
      leavingScreen: 500,
    },
  },
})

const App = () => {
  /**
   * Represents the state for auto login properties.
   * Use in registration page to auto login after registration.
   * @since useAuth autoLogin is not working due to multiple re-renders caused by useQuery
   */
  const [autoLoginProps, setAutoLoginProps] = useState<AutoLoginProps>({
    username: '',
    password: '',
  })

  const autoLoginvalue = { autoLoginProps, setAutoLoginProps }

  return (
    <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
      <AutoLoginContext.Provider value={autoLoginvalue}>
        <AuthProvider>
          <RedwoodApolloProvider
            useAuth={useAuth}
            graphQLClientConfig={{
              httpLinkConfig: { credentials: 'include' },
            }}
          >
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                {process.env.REDWOOD_ENV_PRODUCTION_MODE === 'false' && (
                  <Metadata
                    robots={[
                      'nofollow',
                      'noindex',
                      'noarchive',
                      'nocache',
                      'nosnippet',
                    ]}
                  />
                )}
                <Routes />
              </ThemeProvider>
            </StyledEngineProvider>
          </RedwoodApolloProvider>
        </AuthProvider>
      </AutoLoginContext.Provider>
    </RedwoodProvider>
  )
}

export default App
