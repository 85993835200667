import React, { useState, useEffect, forwardRef, useRef } from 'react'

import { SxProps, Theme, Box } from '@mui/material'
import ImageKit from 'imagekit-javascript'

import { mergeRefs } from 'src/utils/mergeRefs'

let client: ImageKit | undefined = undefined

export const getIKClient = () => {
  if (!client) {
    client = new ImageKit({
      urlEndpoint: process.env.REDWOOD_ENV_IMAGEKIT_PUBLIC_ENDPOINT as string,
    })
  }
  return client
}

interface LQIP {
  active: boolean
}

interface IKImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  loading?: 'lazy'
  lqip?: LQIP
  path?: string
  queryParameters?: Record<string, string | number>
  transformation?: Record<string, string>[]
  sx?: SxProps<Theme>
}

const lqipTransformations = [
  { width: '20', height: '20', quality: '20', blur: '6' },
]

const getSrc = (props: Omit<IKImageProps, 'loading' | 'lqip'>) => {
  const client = getIKClient()
  if (props.path) {
    return client.url({
      path: props.path,
      transformation: props.transformation,
      queryParameters: props.queryParameters,
    })
  }
  if (props.src) {
    return client.url({
      src: props.src,
      transformation: props.transformation,
      queryParameters: props.queryParameters,
    })
  }
  throw new Error('IKImage: Missing path or src')
}

const IKImage = ({
  path,
  transformation,
  queryParameters,
  lqip,
  src,
  ...rest
}: IKImageProps) => {
  const l = lqip?.active
    ? getSrc({ path, src, transformation: lqipTransformations })
    : undefined

  const s = getSrc({ path, src, transformation, queryParameters })
  return <ImageWithLQIP src={s} lqip={l} {...rest} />
}

interface ImageWithLQIPProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string
  lqip?: string
  sx?: SxProps<Theme>
}

const ImageWithLQIP = forwardRef<HTMLImageElement, ImageWithLQIPProps>(
  ({ src, lqip, alt, sx, ...props }, ref) => {
    const [inFrame, setImFrame] = useState(false)
    const imageRef = useRef<HTMLImageElement>(null)
    const combinedRef = mergeRefs([imageRef, ref])

    useEffect(() => {
      let observer: IntersectionObserver
      let didCancel = false
      const el = imageRef.current
      if (el && lqip && !inFrame) {
        if (IntersectionObserver) {
          observer = new IntersectionObserver(
            (entries) => {
              entries?.forEach((entry) => {
                if (
                  !didCancel &&
                  (entry.intersectionRatio > 0 || entry.isIntersecting)
                ) {
                  setImFrame(true)
                  observer.unobserve(el)
                }
              })
            },
            {
              threshold: 0.01,
              rootMargin: '75%',
            }
          )
          observer.observe(el)
        } else {
          setImFrame(true)
        }
      }

      return () => {
        didCancel = true
        if (observer && observer.unobserve && el) {
          observer.unobserve(el)
        }
      }
    }, [src, inFrame, imageRef, lqip])

    return (
      <Box sx={{ ...sx, display: 'flex', overflow: 'hidden' }}>
        <img
          ref={combinedRef}
          src={inFrame ? src : lqip || src}
          alt={alt}
          style={{ objectFit: 'contain' }}
          {...props}
        />
      </Box>
    )
  }
)

export default IKImage
