import React, { useMemo, useState } from 'react'

import {
  ListAlt,
  Category,
  Storefront,
  GroupWork,
  Person,
  LocalMall,
  Class,
  Menu as MenuIcon,
  Settings,
} from '@mui/icons-material'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import SummarizeIcon from '@mui/icons-material/Summarize'
import { AppBar, Box, IconButton, Toolbar } from '@mui/material'

import { routes, Link } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'
import AdminPanel from 'src/components/AdminPanel'

import MyStatsPageIcon from '../../assets/icons/checklistSVG'
import DeliveryPageIcon from '../../assets/icons/DeliveryTruckSVG'
import LogoutIcon from '../../assets/icons/exitSVG'
import MyGroupPageIcon from '../../assets/icons/groupsSVG'
import GroupStatsPageIcon from '../../assets/icons/growthSVG'
import StartpageIcon from '../../assets/icons/homeSVG'
import MilestonesPageIcon from '../../assets/icons/Milestones'
import GetStartedPageIcon from '../../assets/icons/playSVG'
import SettingsPageIcon from '../../assets/icons/settingsSVG'
import SellingPageIcon from '../../assets/icons/shopping-bagSVG'
import ToplistPageIcon from '../../assets/icons/ToplistSVG'

type ShopLayoutProps = {
  children?: React.ReactNode
}

interface MenuOption {
  icon: React.ComponentType<any>
  title: string
  url?: string
  onClick?: () => unknown
}

const AdminLayout = ({ children }: ShopLayoutProps) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const { hasRole, logOut } = useAuth()

  const menuList: MenuOption[] = useMemo(() => {
    const menu: MenuOption[] = hasRole('admin')
      ? [
          {
            icon: StartpageIcon,
            title: 'Startsida',
            url: routes.adminWelcome(),
          },
          {
            icon: Class,
            title: 'Sortiment',
            url: routes.assortments(),
          },
          {
            icon: Category,
            title: 'Produktkategorier',
            url: routes.categories(),
          },
          {
            icon: ListAlt,
            title: 'Produkter',
            url: routes.products(),
          },
          {
            icon: Storefront,
            title: 'Leverantörer',
            url: routes.vendors(),
          },
          {
            icon: GroupWork,
            title: 'Lag',
            url: routes.teams(),
          },
          {
            icon: Person,
            title: 'Användare',
            url: routes.users(),
          },
          {
            icon: LocalMall,
            title: 'Beställningar',
            url: routes.orders(),
          },
          {
            icon: SummarizeIcon,
            title: 'Reports',
            url: routes.reports(),
          },
          {
            icon: QueryStatsIcon,
            title: 'Försälningsperioder',
            url: routes.salesPeriodReport(),
          },
          {
            icon: Settings,
            title: 'Inställningar',
            url: routes.adminChangePassword(),
          },
        ]
      : hasRole('lead')
      ? [
          {
            icon: StartpageIcon,
            title: 'Startsida',
            url: routes.myTeamWelcomePage(),
          },
          {
            icon: MyGroupPageIcon,
            title: 'Min grupp',
            url: routes.usersOnMyTeam(),
          },
          {
            icon: GroupStatsPageIcon,
            title: 'Gruppens försäljning',
            url: routes.teamStatistics(),
          },
          {
            icon: MilestonesPageIcon,
            title: 'Mål & bonus',
            url: routes.teamMilestones(),
          },
          {
            icon: ToplistPageIcon,
            title: 'Topplista',
            url: routes.teamToplist(),
          },
          {
            icon: SellingPageIcon,
            title: 'Jag vill också sälja',
            url: routes.teamleadSellingInformation(),
          },
          {
            icon: MyStatsPageIcon,
            title: 'Min försäljning',
            url: routes.teamleadStatistics(),
          },
          {
            icon: DeliveryPageIcon,
            title: 'Leverans',
            url: routes.teamDelivery(),
          },
          {
            icon: SettingsPageIcon,
            title: 'Inställningar',
            url: routes.teamSettings(),
          },
        ]
      : [
          {
            icon: StartpageIcon,
            title: 'Startsida',
            url: routes.memberWelcome(),
          },
          {
            icon: GetStartedPageIcon,
            title: 'Kom igång',
            url: routes.sellingInformation(),
          },
          {
            icon: GroupStatsPageIcon,
            title: 'Beställningar',
            url: routes.memberStatistics(),
          },
          {
            icon: MilestonesPageIcon,
            title: 'Mål & bonus',
            url: routes.memberMilestones(),
          },
          {
            icon: ToplistPageIcon,
            title: 'Topplista',
            url: routes.memberToplist(),
          },
          {
            icon: DeliveryPageIcon,
            title: 'Leverans',
            url: routes.memberDelivery(),
          },
          {
            icon: SettingsPageIcon,
            title: 'Inställningar',
            url: routes.memberSettings(),
          },
        ]

    menu.push({
      icon: LogoutIcon,
      title: 'Logga ut',
      onClick: () => logOut(),
    })
    return menu
  }, [hasRole, logOut])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const onLogoClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    window.location.href = 'https://lagshoppen.se/'
  }
  return (
    <>
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
      <Box sx={{ display: 'flex' }}>
        <AppBar
          position="fixed"
          sx={{ zIndex: (t) => t.zIndex.drawer + 1, boxShadow: 'none' }}
        >
          <Box
            sx={{
              display: 'flex',
              background: 'white',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {handleDrawerToggle && (
                <IconButton
                  color="default"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{
                    marginLeft: 1,
                    display: 'block',
                  }}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Box sx={{ cursor: 'pointer' }}>
                <a onClick={onLogoClick}>
                  <img
                    style={{ height: 25, paddingTop: '2px' }}
                    className="Logo"
                    alt="lagshoppen"
                    src="/lagshoppen-logo.svg"
                  />
                </a>
              </Box>
            </Box>
          </Box>
        </AppBar>
        <AdminPanel
          list={menuList}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box sx={{ flexGrow: 1, marginLeft: { md: '245px' } }}>
          <Toolbar sx={{ height: '50px' }} />
          {children}
        </Box>
      </Box>
    </>
  )
}

export default AdminLayout
