const Settings = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    x="0"
    y="0"
    viewBox="0 0 682.667 682.667"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <defs strokeWidth="29">
        <clipPath id="a" clipPathUnits="userSpaceOnUse" strokeWidth="29">
          <path
            d="M0 512h512V0H0Z"
            fill="#f6a237"
            opacity="1"
            data-original="#000000"
            strokeWidth="29"
          ></path>
        </clipPath>
      </defs>
      <g
        clipPath="url(#a)"
        transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
        strokeWidth="29"
      >
        <path
          d="M0 0c0-34.391 27.879-62.27 62.27-62.27 34.39 0 62.269 27.879 62.269 62.27 0 34.391-27.879 62.27-62.269 62.27C27.879 62.27 0 34.391 0 0Zm11.266 154.503 18.068 42.164h65.871l18.067-42.164 57.299-33.082 45.549 5.435 32.936-57.045-27.482-36.729v-66.164l27.482-36.729-32.936-57.045-45.549 5.435-57.299-33.082-18.067-42.164H29.334l-18.068 42.164-57.298 33.082-45.549-5.435-32.936 57.045 27.481 36.729v66.164l-27.481 36.729 32.936 57.045 45.549-5.435z"
          transform="translate(144.517 276)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="29"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="m0 0 42.985-24.817 15.22-35.516h55.483l15.219 35.516 48.264 27.864 38.367-4.577 27.742 48.051-23.148 30.937v55.73l23.148 30.937-27.742 48.051-38.367-4.577-42.361 24.457"
          transform="translate(248.72 100.333)"
          fill="none"
          stroke={color || '#f6a237'}
          strokeWidth="29"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
      </g>
    </g>
  </svg>
)
export default Settings
