const Exit = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="28"
    height="28"
    x="0"
    y="0"
    viewBox="0 0 682.667 682.667"
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <defs>
        <clipPath id="b" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 512h512V0H0Z"
            fill="#f6a237"
            opacity="1"
            data-original="#000000"
          ></path>
        </clipPath>
      </defs>
      <mask id="a">
        <rect
          width="100%"
          height="100%"
          fill="#ffffff"
          opacity="1"
          data-original="#ffffff"
        ></rect>
      </mask>
      <g mask="url(#a)">
        <g
          clipPath="url(#b)"
          transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
        >
          <path
            d="M0 0v-60c0-22.092-17.908-40-40-40h-241c-22.092 0-40 17.908-40 40v392c0 22.092 17.908 40 40 40h241c22.092 0 40-17.908 40-40v-60"
            transform="translate(341 120)"
            fill="none"
            stroke="#f6a237"
            strokeWidth="30"
            strokeLinecap="square"
            strokeLinejoin="miter"
            strokeMiterlimit="10"
            strokeDasharray="none"
            strokeOpacity=""
            data-original="#000000"
            opacity="1"
            className=""
          ></path>
          <path
            d="M0 0h-271"
            transform="translate(468 256)"
            fill="none"
            stroke="#f6a237"
            strokeWidth="30"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeDasharray="none"
            strokeOpacity=""
            data-original="#000000"
            opacity="1"
            className=""
          ></path>
          <path
            d="m0 0 66-66-66-66"
            transform="translate(418 322)"
            fill="none"
            stroke={color || '#f6a237'}
            strokeWidth="30"
            strokeLinecap="square"
            strokeLinejoin="miter"
            strokeMiterlimit="10"
            strokeDasharray="none"
            strokeOpacity=""
            data-original="#000000"
            opacity="1"
            className=""
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
export default Exit
