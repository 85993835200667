import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface LineItem {
  id: string
  quantity: number
  volume: string
}

export interface Cart {
  lineItems: { [productId: string]: LineItem }
  quantity: number
}

interface State {
  assortmentId: string | null
  orderId: string | null
  latestSellingPeriodId: string | null
  carts: { [assortmentId: string]: Cart }
}

const initialState: State = {
  assortmentId: null,
  latestSellingPeriodId: null,
  orderId: null,
  carts: {},
}

const countItems = (cart: Cart) =>
  Object.values(cart.lineItems).reduce((a, l) => {
    a += l.quantity
    return a
  }, 0)

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setAssortmentId: (s, { payload }: PayloadAction<string | null>) => {
      s.assortmentId = payload
    },
    setLatestSellingPeriodId: (
      s,
      { payload }: PayloadAction<string | null>
    ) => {
      s.latestSellingPeriodId = payload
    },

    setOrderId: (s, { payload }: PayloadAction<string | null>) => {
      s.orderId = payload
    },
    onAdd: (
      s,
      {
        payload: { productId, volume },
      }: PayloadAction<{ productId: string; volume?: string }>
    ) => {
      if (!s.assortmentId) return
      if (!s.carts[s.assortmentId]) {
        s.carts[s.assortmentId] = { lineItems: {}, quantity: 0 }
      }
      const c = s.carts[s.assortmentId]
      if (c.lineItems[productId]) {
        c.lineItems[productId].quantity += 1
      } else {
        c.lineItems[productId] = {
          id: productId,
          quantity: 1,
          volume: volume ?? '',
        }
      }
      c.quantity = countItems(c)
    },
    onRemove: (
      s,
      { payload: { productId } }: PayloadAction<{ productId: string }>
    ) => {
      if (!s.assortmentId) return
      const c = s.carts[s.assortmentId]
      if (!c || !c.lineItems[productId]) {
        return
      }
      if (c.lineItems[productId].quantity > 1) {
        c.lineItems[productId].quantity -= 1
      } else {
        delete c.lineItems[productId]
      }
      c.quantity = countItems(c)
    },
    clear: (
      s,
      { payload: { assortmentId } }: PayloadAction<{ assortmentId?: string }>
    ) => {
      if (assortmentId) {
        delete s.carts[assortmentId]
      } else {
        if (s.assortmentId) {
          delete s.carts[s.assortmentId]
        }
      }
    },
  },
})

export const orderActions = orderSlice.actions

export default orderSlice.reducer
