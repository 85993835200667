// Starting redwoodjs v6.0.0
// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
// @todo remove import and use the auto-imported components
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { useEffect } from 'react'

import { Font } from '@react-pdf/renderer'

import { Router, Route, Set, PrivateSet } from '@redwoodjs/router'

import { useAuth } from './auth'
import AdminLayout from './layouts/AdminLayout/AdminLayout'
import ShopLayout from './layouts/ShopLayout/ShopLayout'

const Routes = () => {
  useEffect(() => {
    try {
      Font.register({
        family: 'Open Sans',
        fonts: [
          {
            src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
          },
          {
            src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
            fontWeight: 700,
          },
        ],
      })
    } catch (error) {
      console.error('Error registering fonts', error)
    }
  }, [])

  return (
    <Router useAuth={useAuth}>
      <PrivateSet unauthenticated="login" roles="admin" wrap={AdminLayout}>
        <Route path="/admin/welcome" page={AdminAdminWelcomePage} name="adminWelcome" />
        <Route path="/admin/assortments/new" page={AdminAssortmentNewAssortmentPage} name="newAssortment" />
        <Route path="/admin/assortments/{id}/edit" page={AdminAssortmentEditAssortmentPage} name="editAssortment" />
        <Route path="/admin/assortments" page={AdminAssortmentAssortmentsPage} name="assortments" />
        <Route path="/admin/vendors/new" page={AdminVendorNewVendorPage} name="newVendor" />
        <Route path="/admin/vendors/{id}/edit" page={AdminVendorEditVendorPage} name="editVendor" />
        <Route path="/admin/vendors" page={AdminVendorVendorsPage} name="vendors" />
        <Route path="/admin/products/new" page={AdminProductNewProductPage} name="newProduct" />
        <Route path="/admin/products/{id}/edit" page={AdminProductEditProductPage} name="editProduct" />
        <Route path="/admin/products" page={AdminProductProductsPage} name="products" />
        <Route path="/admin/categories/new" page={AdminCategoryNewCategoryPage} name="newCategory" />
        <Route path="/admin/categories/{id}/edit" page={AdminCategoryEditCategoryPage} name="editCategory" />
        <Route path="/admin/categories" page={AdminCategoryCategoriesPage} name="categories" />
        <Route path="/admin/orders/new" page={AdminOrderNewOrderPage} name="newOrder" />
        <Route path="/admin/orders/{id}/edit" page={AdminOrderEditOrderPage} name="editOrder" />
        <Route path="/admin/orders" page={AdminOrderOrdersPage} name="orders" />
        <Route path="/admin/teams/new" page={AdminTeamNewTeamPage} name="newTeam" />
        <Route path="/admin/teams/{id}/edit" page={AdminTeamEditTeamPage} name="editTeam" />
        <Route path="/admin/teams" page={AdminTeamTeamsPage} name="teams" />
        <Route path="/admin/users/new" page={AdminUserNewUserPage} name="newUser" />
        <Route path="/admin/users/{id}/edit" page={AdminUserEditUserPage} name="editUser" />
        <Route path="/admin/users" page={AdminUserUsersPage} name="users" />
        <Route path="/admin/change-password" page={MemberChangePasswordPage} name="adminChangePassword" />
        <Route path="/admin/reports" page={AdminReportsPage} name="reports" />
        <Route path="/admin/sales-period-report" page={AdminSalesPeriodReportPage} name="salesPeriodReport" />
      </PrivateSet>
      <PrivateSet unauthenticated="login" roles="lead" wrap={AdminLayout}>
        <Route path="/my-team" page={LeadMyTeamWelcomePage} name="myTeamWelcomePage" />
        <Route path="/my-team/users/new" page={LeadMyTeamNewUserOnMyTeamPage} name="newUserOnMyTeam" />
        <Route path="/my-team/users/{id}/edit" page={LeadMyTeamEditUsersOnTeamsPage} name="editUserOnMyTeam" />
        <Route path="/my-team/users" page={LeadMyTeamUsersOnMyTeamPage} name="usersOnMyTeam" />
        <Route path="/my-team/settings" page={LeadTeamSettingsPage} name="teamSettings" />
        <Route path="/my-team/change-password" page={MemberChangePasswordPage} name="changePassword" />
        <Route path="/my-team/statistics/member/{username}/{from}/{to}" page={LeadMyTeamTeamStatisticsMemberPage} name="teamStatisticsMember" />
        <Route path="/my-team/statistics" page={LeadMyTeamTeamStatisticsPage} name="teamStatistics" />
        <Route path="/my-team/selling-information" page={LeadMyTeamTeamleadSellingInformationPage} name="teamleadSellingInformation" />
        <Route path="/my-team/my-statistics" page={LeadMyTeamTeamleadStatisticsPage} name="teamleadStatistics" />
        <Route path="/my-team/delivery-info" page={LeadMyTeamTeamDeliveryPage} name="teamDelivery" />
        <Route path="/my-team/bonus" page={MemberMemberMilestonesPage} name="teamMilestones" />
        <Route path="/my-team/toplist" page={MemberMemberToplistPage} name="teamToplist" />
      </PrivateSet>

      <PrivateSet unauthenticated="login" roles="member" wrap={AdminLayout}>
        <Route path="/member/statistics" page={MemberMemberStatisticsPage} name="memberStatistics" />
        <Route path="/member/welcome" page={MemberMemberWelcomePage} name="memberWelcome" />
        <Route path="/member/selling-information" page={MemberSellingInformationPage} name="sellingInformation" />
        <Route path="/member/settings" page={MemberMemberSettingsPage} name="memberSettings" />
        <Route path="/member/delivery" page={MemberMemberDeliveryPage} name="memberDelivery" />
        <Route path="/member/bonus" page={MemberMemberMilestonesPage} name="memberMilestones" />
        <Route path="/member/toplist" page={MemberMemberToplistPage} name="memberToplist" />
      </PrivateSet>

      {/* <Set wrap={WebshopLayout}>
        <Route path="/shop" page={ShopHomePage} name="home" />
        <Route path="/shop/checkout" page={ShopCheckoutPage} name="checkout" />
        <Route path="/orderconfirmation/{orderId:String}" page={ShopOrderConfirmationPage} name="orderConfirmation" />
        <Route path="/shop/product/{id}" page={ShopProductInfoPage} name="productInfoPage" />
        <Route path="/shop/{referalCode:String}" page={ShopHomePage} name="homeWithReferal" />
        <Route path="/kundinfo" page={MarketingKundinfoPage} name="kundinfo" prerender />
        <Route path="/terms" page={TermsPage} name="terms" prerender />
        <Route path="/about-us" page={AboutUsPage} name="aboutUs" prerender />
        <Route path="/integritetspolicy" page={GdprPage} name="gdpr" prerender />
      </Set> */}

      <Set wrap={ShopLayout}>
        {/* <Route path="/" page={LandingPage} name="landing" prerender /> */}
        <Route path="/login" page={ShopLoginPage} name="login" />
        <Route path="/auto-login" page={AutoLoginPage} name="autoLogin" />
        {/* <Route path="/contact" page={ShopContactPage} name="contact" prerender />
        <Route path="/form-submitted" page={FormSubmittedPage} name="formSubmitted" prerender />
        <Route path="/forsaljning" page={ForsaljningPage} name="forsaljning" prerender /> */}
        <Route path="/forgot-password" page={ShopForgotPasswordPage} name="forgotPassword" />
        <Route path="/reset-password" page={ShopResetPasswordPage} name="resetPassword" />
        <Route path="/registrering/{id}" page={RegistrationPage} name="registration" />
        <Route path="/uppstart/{id}" page={UppstartPage} name="uppstart" />
        {/* <Route path="/skannad-qr-kod" page={MarketingSecretQRCodePage} name="secretQRCode" prerender />
        <Route path="/tjana-pengar/lagforsaljning" page={MarketingTjanaPengarTillLagetPage} name="lagforsaljning" prerender />
        <Route path="/tjana-pengar/klassforsaljning" page={MarketingTjanaPengarTillKlassenPage} name="klassforsaljning" prerender />
        <Route path="/tjana-pengar/foreningsforsaljning" page={MarketingTjanaPengarTillForeningenPage} name="foreningsforsaljning" prerender />
        <Route path="/tjana-pengar/tjana-pengar-till-studenten" page={MarketingTjanaPengarTillStudentenPage} name="studentForsaljning" prerender />
        <Route path="/tjana-pengar/tjana-pengar-till-laget" page={MarketingTjanaPengarLagetPage} name="tjanaPengarLaget" prerender />
        <Route path="/tjana-pengar/faq" page={MarketingFAQPage} name="faq" prerender />
        <Route path="/info-saljare" page={MarketingSaGarDetTillSaljarePage} name="saGarDetTillSaljare" prerender />
        <Route path="/tips" page={MarketingTipsOchGuiderPage} name="tipsOchGuider" prerender />
        <Route path="/vad-kan-man-salja-med-laget" page={MarketingVadKanManSaljaLagetPage} name="vadKanManSaljaMedLaget" prerender />
        <Route path="/vad-kan-man-salja" page={MarketingVadKanManSaljaPage} name="vadKanManSalja" prerender />
        <Route path="/vara-sortiment/lattsalda-produkter" page={MarketingLattsaldaProdukterPage} name="lattsaldaProdukter" prerender />
        <Route path="/vara-sortiment/{assortment:string}" page={MarketingSaljaPage} name="salja" prerender />
        <Route path="/vara-sortiment" page={MarketingVaraSortimentPage} name="varaSortiment" prerender />
        <Route path="/vara-sortiment/salja-te/tips" page={MarketingGuidePagesSaljaTeGuidePage} name="saljaTeGuide" prerender />
        <Route path="/vara-sortiment/salja-blommor/tips" page={MarketingGuidePagesSaljaBlommorGuidePage} name="saljaBlommorGuide" prerender />
        <Route path="/vara-sortiment/salja-korv/tips" page={MarketingGuidePagesSaljaKorvGuidePage} name="saljaKorvGuide" prerender />
        <Route path="/vara-sortiment/salja-kryddor/tips" page={MarketingGuidePagesSaljaKryddorGuidePage} name="saljaKryddorGuide" prerender />
        <Route path="/vara-sortiment/salja-salami/tips" page={MarketingGuidePagesSaljaSalamiGuidePage} name="saljaSalamiGuide" prerender />
        <Route path="/vara-sortiment/salja-polkagrisar/tips" page={MarketingGuidePagesSaljaPolkaGuidePage} name="saljaPolkaGuide" prerender />
        <Route path="/vara-sortiment/salja-olivolja/tips" page={MarketingGuidePagesSaljaOlivoljaGuidePage} name="saljaOlivoljaGuide" prerender />
        <Route path="/vara-sortiment/salja-toalettpapper/tips" page={MarketingGuidePagesSaljaToapapperGuidePage} name="saljaToapapperGuide" prerender />
        <Route path="/vara-sortiment/salja-fryspasar/tips" page={MarketingGuidePagesSaljaFryspasarGuidePage} name="saljaFryspasarGuide" prerender />
        <Route path="/vara-sortiment/salja-kakor/tips" page={MarketingGuidePagesSaljaKakorGuidePage} name="saljaKakorGuide" prerender />
        <Route path="/vara-sortiment/salja-klader/tips" page={MarketingGuidePagesSaljaKladerGuidePage} name="saljaKladerGuide" prerender />
        <Route path="/vara-sortiment/salja-grillkol/tips" page={MarketingGuidePagesSaljaGrillkolGuidePage} name="saljaGrillkolGuide" prerender />
        <Route path="/vara-sortiment/salja-froer/tips" page={MarketingGuidePagesSaljaFroerGuidePage} name="saljaFroerGuide" prerender />
        <Route path="/vara-sortiment/salja-kokosbollar/tips" page={MarketingGuidePagesSaljaKokosbollarGuidePage} name="saljaKokosbollarGuide" prerender />
        <Route path="/vara-sortiment/salja-choklad/tips" page={MarketingGuidePagesSaljaChokladGuidePage} name="saljaChokladGuide" prerender />
        <Route path="/vara-sortiment/salja-reflexer/tips" page={MarketingGuidePagesSaljaReflexerGuidePage} name="saljaReflexerGuide" prerender />
        <Route path="/vara-sortiment/salja-amaryllis/tips" page={MarketingGuidePagesSaljaAmaryllisGuidePage} name="saljaAmaryllisGuide" prerender />
        <Route path="/fakta-om-tulpaner" page={MarketingGuidePagesFaktaTulpanerPage} name="faktaTulpaner" prerender />
        <Route path="/saker-att-salja-med-klassen" page={MarketingGuidePagesSakerAttSaljaMedKlassenPage} name="sakerAttSaljaMedKlassen" prerender />
        <Route path="/klassresor" page={MarketingKlassresaPagesKlassresorPage} name="klassresor" prerender />
        <Route path="/klassresor/klassresa-vastra-gotaland" page={MarketingKlassresaPagesKlassresaVastraGotalandPage} name="klassresaVastraGotaland" prerender />
        <Route path="/klassresor/klassresa-med-overnattning" page={MarketingKlassresaPagesKlassresaMedOvernattningPage} name="klassresaMedOvernattning" prerender />
        <Route path="/klassresor/klassresa-liseberg" page={MarketingKlassresaPagesKlassresaLisebergPage} name="klassresaLiseberg" prerender />
        <Route path="/klassresor/klassresa-goteborg" page={MarketingKlassresaPagesKlassresaGoteborgPage} name="klassresaGoteborg" prerender />
        <Route path="/klassresor/paket-klassresa" page={MarketingKlassresaPagesKlassresaPaketPage} name="klassresaPaket" prerender />
        <Route path="/klassresor/klassresa-stockholm" page={MarketingKlassresaPagesKlassresaStockholmPage} name="klassresaStockholm" prerender />
        <Route path="/klassresor/grona-lund-klassresa" page={MarketingKlassresaPagesKlassresaGronalundPage} name="klassresaGronalund" prerender />
        <Route path="/klassresor/klassresa-gotland" page={MarketingKlassresaPagesKlassresaGotlandPage} name="klassresaGotland" prerender />
        <Route path="/klassresor/klassresa-barcelona" page={MarketingKlassresaPagesKlassresaBarcelonaPage} name="klassresaBarcelona" prerender />
        <Route path="/studentresa" page={MarketingKlassresaPagesStudentresaPage} name="studentresa" prerender />
        <Route path="/klassens-nomineringar" page={MarketingKlassensPage} name="klassens" prerender />
        <Route path="/roliga-studentcitat" page={MarketingRoligaStudentcitatPage} name="roligaStudentcitat" prerender />
        <Route path="/mosspatagning" page={MarketingMosspatagningPage} name="mosspatagning" prerender />
        <Route path="/uf-foretag-ideer" page={MarketingUfIdeerPage} name="ufForetagIdeer" prerender />
        <Route path="/klasskassa" page={MarketingKlasskassaPage} name="klasskassa" prerender />
        <Route path="/klassforalder" page={MarketingKlassforalderPage} name="klassforalder" prerender />
        <Route path="/champagnefrukost-student" page={MarketingChampagnefrukostPage} name="champagnefrukost" prerender />
        <Route path="/tips-till-studenten" page={MarketingTipsStudentenPage} name="tipsStudenten" prerender />
        <Route path="/hur-mycket-kostar-studenten" page={MarketingKostarStudentenPage} name="kostarStudenten" prerender />
        <Route path="/skoldisco" page={MarketingSkoldiscoPage} name="skoldisco" prerender />
        <Route path="/sveriges-storsta-fotbollscuper" page={MarketingSportGuidePageSverigesFotbollscuperPage} name="sverigesFotbollscuper" prerender />
        <Route path="/sveriges-storsta-handbollscuper" page={MarketingSportGuidePageSverigesHandbollscuperPage} name="sverigesHandbollscuper" prerender />
        <Route path="/sveriges-storsta-innebandycuper" page={MarketingSportGuidePageSverigesInnebandycuperPage} name="sverigesInnebandycuper" prerender />
        <Route path="/sveriges-storsta-basketcuper" page={MarketingSportGuidePageSverigesBasketCuperPage} name="sverigesBasketcuper" prerender />
        <Route path="/intresseanmalan" page={MarketingIntresseanmalanPage} name="intresseanmalan" prerender />
        <Route path="/referenser" page={MarketingReferenserPage} name="referenser" prerender /> */}
        {/* <Route path="/web/{slug}" page={BlogsPage} name="blogs" prerender /> */}
        <Route notfound page={NotFoundPage} />
      </Set>
    </Router>
  )
}

export default Routes
