const Growth = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    x="0"
    y="0"
    viewBox="0 0 682.667 682.667"
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <defs strokeWidth="23">
        <clipPath id="a" clipPathUnits="userSpaceOnUse" strokeWidth="23">
          <path
            d="M0 512h512V0H0Z"
            fill="#f6a237"
            opacity="1"
            data-original="#000000"
            strokeWidth="23"
          ></path>
        </clipPath>
        <clipPath id="b" clipPathUnits="userSpaceOnUse" strokeWidth="23">
          <path
            d="M0 512h512V0H0Z"
            fill="#f6a237"
            opacity="1"
            data-original="#000000"
            strokeWidth="23"
          ></path>
        </clipPath>
        <clipPath id="c" clipPathUnits="userSpaceOnUse" strokeWidth="23">
          <path
            d="M0 512h512V0H0Z"
            fill="#f6a237"
            opacity="1"
            data-original="#000000"
            strokeWidth="23"
          ></path>
        </clipPath>
      </defs>
      <path
        d="M0 0v-208.418"
        transform="matrix(1.33333 0 0 -1.33333 458.903 352.023)"
        fill="none"
        stroke="#f6a237"
        strokeWidth="23"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeDasharray="none"
        strokeOpacity=""
        data-original="#000000"
        opacity="1"
      ></path>
      <g
        clipPath="url(#b)"
        transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
        strokeWidth="23"
      >
        <path
          d="M0 0v80.158h48.097L-56.113 200.4l-104.21-120.242h48.097"
          transform="translate(456.403 272.036)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="23"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
      </g>
      <path
        d="M0 0v128.257"
        transform="matrix(1.33333 0 0 -1.33333 608.538 629.914)"
        fill="none"
        stroke="#f6a237"
        strokeWidth="23"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeDasharray="none"
        strokeOpacity=""
        data-original="#000000"
        opacity="1"
      ></path>
      <path
        d="M0 0v264.532h-112.226V0"
        transform="matrix(1.33333 0 0 -1.33333 416.15 629.914)"
        fill="none"
        stroke="#f6a237"
        strokeWidth="23"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeDasharray="none"
        strokeOpacity=""
        data-original="#000000"
        opacity="1"
      ></path>
      <path
        d="M0 0v120.239h-112.226V-48.1"
        transform="matrix(1.33333 0 0 -1.33333 223.764 565.78)"
        fill="none"
        stroke="#f6a237"
        strokeWidth="23"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeDasharray="none"
        strokeOpacity=""
        data-original="#000000"
        opacity="1"
      ></path>
      <path
        d="M0 0v16.032"
        transform="matrix(1.33333 0 0 -1.33333 223.764 629.914)"
        fill="none"
        stroke="#f6a237"
        strokeWidth="23"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeDasharray="none"
        strokeOpacity=""
        data-original="#000000"
        opacity="1"
      ></path>
      <path
        d="m0 0-50.229-119.686"
        transform="matrix(1.33333 0 0 -1.33333 568.29 252.577)"
        fill="none"
        stroke="#f6a237"
        strokeWidth="23"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeDasharray="none"
        strokeOpacity=""
        data-original="#000000"
        opacity="1"
      ></path>
      <g
        clipPath="url(#c)"
        transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
        strokeWidth="23"
      >
        <path
          d="M0 0c0-14.244-9.937-25.791-22.194-25.791-12.258 0-22.195 11.547-22.195 25.791s9.937 25.791 22.195 25.791C-9.937 25.791 0 14.244 0 0Z"
          transform="translate(388.566 302.354)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="23"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
        <path
          d="M0 0c0-14.244-9.937-25.791-22.194-25.791-12.258 0-22.194 11.547-22.194 25.791s9.936 25.791 22.194 25.791C-9.937 25.791 0 14.244 0 0Z"
          transform="translate(456.403 220.523)"
          fill="none"
          stroke="#f6a237"
          strokeWidth="23"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
      </g>
      <g
        clipPath="url(#a)"
        transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
        strokeWidth="23"
      >
        <path
          d="M0 0h497"
          transform="translate(7.5 39.565)"
          fill="none"
          stroke={color || '#F6A237'}
          strokeWidth="23"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
          opacity="1"
        ></path>
      </g>
    </g>
  </svg>
)
export default Growth
